.centeredCards {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cardBottom {
  padding-bottom: 10px;
}

.cardImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cropped {
  width: 100%;
  height: 200px;
}
