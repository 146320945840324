@media screen and (max-width: 622px) {
  .progressBars {
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
    align-items: center;
  }
}

.centeredForm {
  padding-top: 20px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.statisticsFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.left {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.left div {
  margin-right: 20px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.progressBars {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
