@media only screen and (max-width: 994px) {
  .categories {
    flex-wrap: wrap;
  }
}

.test {
  /* background: black; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.categories {
  width: "100%";
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.highLevel {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.boxLevel {
  flex-direction: row;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.buttonPadding {
  max-width: 300px;
  min-width: 300px;
  max-height: 90px;
  min-height: 90px;
  margin-bottom: 10px;
}

.buttonPadding span {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
