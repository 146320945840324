.flex {
  display: flex;
  flex-direction: row;
}

.articleFlex {
  /* background-color: red;  */
  display: flex;
  flex-direction: row;
}

.leftArt {
  height: 100%;
  width: 100%;
  flex: 0;
  padding: 0;
}

.rightArt {
  height: 100%;
  width: 100%;
  flex: 3;
  display: flex;
  flex-direction: column;
}

.test {
  margin-bottom: 5px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}
