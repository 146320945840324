.centered {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    top: 50px;
    padding-bottom: 35px;
    min-width: 600px;
}

.centered img {
    padding-bottom: 10px;
}