* {
  margin: 0;
  padding: 0;
}

.centeredHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 80%;
  width: 100%;
  /* background-color: red; */
}

.landingDiv {
  height: 100vh;
}

.landingFlex {
  display: flex;
  flex-direction: column;
}

.intro {
  height: 90vh;
}

@media screen and (max-height: 500px) {
  .intro {
    margin-top: 50vh;
  }
}

.scroll {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background-color: blue; */
}

.vumslogo {
  width: 250px;
}

.descriptionText {
  font-style: italic;
  width: 40%;
}
.left p,
.right p {
  /* color: red; */
  width: 500px;
  text-align: justify;
}

.left,
.right {
  padding: 30px;
}

.imageEventData {
  width: 500px;
}

/* .vl {
  border-left: 3px solid black;
  height: 100%;
  margin: 30px;
} */

.avatars {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
